/* Customize the label (the container) */
.radio-container {
    @apply block relative cursor-pointer;

    padding-left: 35px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radio-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;

    @apply bg-gray-50 rounded-full border border-purple;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
    @apply border-gray-400;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
    @apply bg-purple;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 1px;
    left: 1px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: transparent;
}
