.invite-list {
    .search-bar {
        @apply flex items-center py-4 px-2 bg-gray-100 sticky z-20 md:top-0 md:mb-4 md:px-0;

        top: 75px; /* On mobile, pin below main navigation header (the value is the main nav height) */

        &::before {
            @apply bg-gray-100;
        }
    }


.invite-list-table table {
    th {
        @apply md:z-10 md:sticky md:bg-white;

        &:first-child::before {
             @apply md:bg-white;
         }
    }
}

.invite-list-table table th:first-child::before, .search-bar::before {
    @apply md:block md:absolute md:top-0 md:bottom-0;
}

    @screen md {
        .invite-list-table table {
            th {
                box-shadow: 2px 1px 3px rgba(50, 50, 93, .02), 0 1px 0 rgba(0, 0, 0, .02); /* Box shadow only on bottom and right */
                top: 84px; /* On desktop, pin right below search bar */
            }
        }

        /**
         * When the table headers and the search bar are stickied, we need to
         * apply a slight left border so the DataTable groups borders are not
         * displayed below the stickied element.
         */
        .invite-list-table table th:first-child::before, .search-bar::before {
            left: -1px;
            content: " ";
            width: 2px;
        }
    }
}
