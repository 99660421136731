.number-picker {
    /* Hide the Chrome/Firefox spinner for the HTML5 number input */
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    /* Hide the "x" in IE for the HTML5 number input */
    .someinput::-ms-clear {
        width: 0;
        height: 0;
    }
}