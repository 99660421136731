.wall-designer {
    .cover-block, .event-menu {
        height: 800px;
    }

    .marvel-device .cover-block {
        height: 100% !important;
    }

    .blocks-drag-list {
        @apply pb-4;

        .draggable-block {
            @screen md {
                max-width: 387px;
            }
        }

        .sortable-ghost {
            opacity: 0.2;

            &.sortable-ghost .fas.fa-plus-circle {
                @apply hidden;
            }
        }
    }
}
