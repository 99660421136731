.ql-container {
    font-family: inherit !important;
    font-size: inherit !important;
}

.ql-editor {
    min-height: 0px !important;
    padding: 0px !important;
    font-size: inherit !important;

    &.ql-blank:before {
        left: 0px !important;
    }

    h1, h2 {
        font-size: inherit !important;
    }
}

.ql-bubble {
    .ql-tooltip {
        @apply z-10 !important;
    }

    .ql-toolbar {
        @apply pl-4;

        .ql-formats:first-child {
            @apply ml-0 !important;
        }
    }
}

.ql-font.ql-picker.ql-expanded .ql-picker-options {
    @apply overflow-y-auto rounded-md max-h-xs;
}

.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: 'Small' !important;
    font-size: 10px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: 'Large' !important;
    font-size: 18px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: 'Extra' !important;
    font-size: 32px !important;
}

iframe.ql-video {
    @apply w-full h-80;
}

@define-mixin qlFont $name, $shortName, $fontFamily {
    .ql-toolbar .ql-font span[data-value="$(shortName)"]::before, .ql-font-$(shortName) {
        font-family: $fontFamily;
    }

    .ql-bubble .ql-picker.ql-font .ql-picker-label[data-value="$(shortName)"]::before,
    .ql-bubble .ql-picker.ql-font .ql-picker-item[data-value="$(shortName)"]::before {
        content: $name;
    }
}

$openSans: "Open Sans", sans-serif;
@mixin qlFont 'Open Sans', Open-Sans, $openSans;

$roboto: "Roboto", sans-serif;
@mixin qlFont 'Roboto', Roboto, $roboto;

$anton: "Anton", sans-serif;
@mixin qlFont 'Anton', Anton, $anton;

$archivoBlack: "Archivo Black", sans-serif;
@mixin qlFont 'Archivo Black', Archivo-Black, $archivoBlack;

$abhayaLibre: "Abhaya Libre", serif;
@mixin qlFont 'Abhaya Libre', Abhaya-Libre, $abhayaLibre;

$comfortaa: "Comfortaa", cursive;
@mixin qlFont 'Comfortaa', Comfortaa, $comfortaa;

$dancingScript: "Dancing Script", cursive;
@mixin qlFont 'Dancing Script', Dancing-Script, $dancingScript;

$josefinSans: "Josefin Sans", sans-serif;
@mixin qlFont 'Josefin Sans', Josefin-Sans, $josefinSans;

$josefinSlab: "Josefin Slab", serif;
@mixin qlFont 'Josefin Slab', Josefin-Slab, $josefinSlab;

$lato: "Lato", sans-serif;
@mixin qlFont 'Lato', Lato, $lato;

$lobsterTwo: "Lobster Two", cursive;
@mixin qlFont 'Lobster Two', Lobster-Two, $lobsterTwo;

$montserrat: "Montserrat", sans-serif;
@mixin qlFont 'Montserrat', Montserrat, $montserrat;

$oldStandardTT: "Old Standard TT", serif;
@mixin qlFont 'Old Standard TT', Old-Standard-TT, $oldStandardTT;

$overlock: "Overlock", cursive;
@mixin qlFont 'Overlock', Overlock, $overlock;

$PTSerif: "PT Serif", serif;
@mixin qlFont 'PT Serif', PT-Serif, $PTSerif;

$pacifico: "Pacifico", cursive;
@mixin qlFont 'Pacifico', Pacifico, $pacifico;

$parisienne: "Parisienne", cursive;
@mixin qlFont 'Parisienne', Parisienne, $parisienne;

$playfairDisplay: "Playfair Display", serif;
@mixin qlFont 'Playfair Display', Playfair-Display, $playfairDisplay;

$raleway: "Raleway", sans-serif;
@mixin qlFont 'Raleway', Raleway, $raleway;

$rochester: "Rochester", cursive;
@mixin qlFont 'Rochester', Rochester, $rochester;

$rokkitt: "Rokkitt", serif;
@mixin qlFont 'Rokkitt', Rokkitt, $rokkitt;

$sacramento: "Sacramento", cursive;
@mixin qlFont 'Sacramento', Sacramento, $sacramento;

$satisfy: "Satisfy", cursive;
@mixin qlFont 'Satisfy', Satisfy, $satisfy;

$sourceSansPro: "Source Sans Pro", sans-serif;
@mixin qlFont 'Source Sans Pro', Source-Sans-Pro, $sourceSansPro;

$sourceSerifPro: "Source Serif Pro", serif;
@mixin qlFont 'Source Serif Pro', Source-Serif-Pro, $sourceSerifPro;

$ubuntu: "Ubuntu", sans-serif;
@mixin qlFont 'Ubuntu', Ubuntu, $ubuntu;

$arial: Arial, Helvetica, sans-serif;
@mixin qlFont 'Arial', Arial, $arial;

$comicSansMS: Comic Sans MS, cursive;
@mixin qlFont 'Comic Sans MS', Comic-Sans-MS, $comicSansMS;

$courierNew: Courier New, Courier, monospace;
@mixin qlFont 'Courier New', Courier-New, $courierNew;

$georgia: Georgia, serif;
@mixin qlFont 'Georgia', Georgia, $georgia;

$lucidaSansUnicode: Lucida Sans Unicode, Lucida Grande, sans-serif;
@mixin qlFont 'Lucida Sans Unicode', Lucida-Sans-Unicode, $lucidaSansUnicode;

$tahoma: Tahoma, Geneva, sans-serif;
@mixin qlFont 'Tahoma', Tahoma, $tahoma;

$timesNewRoman: Times New Roman, Times, serif;
@mixin qlFont 'Times New Roman', Times-New-Roman, $timesNewRoman;

$trebuchetMS: Trebuchet MS, Helvetica, sans-serif;
@mixin qlFont 'Trebuchet MS', Trebuchet-MS, $trebuchetMS;

$verdana: Verdana, Geneva, sans-serif;
@mixin qlFont 'Verdana', Verdana, $verdana;

$helvetica: Helvetica, Arial, sans-serif;
@mixin qlFont 'Helvetica', Helvetica, $helvetica;

$lucidaConsole: Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
@mixin qlFont 'Lucida Console', Lucida-Console, $lucidaConsole;

$lucidaGrande: Lucida Grande, Lucida Sans Unicode, Lucida Sans, Geneva, Verdana, sans-serif;
@mixin qlFont 'Lucida Grande', Lucida-Grande, $lucidaGrande;

$palatino: Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
@mixin qlFont 'Palatino', Palatino, $palatino;

$geneva: Geneva, Tahoma, Verdana, sans-serif;
@mixin qlFont 'Geneva', Geneva, $geneva;

$monaco: monaco, Consolas, Lucida Console, monospace;
@mixin qlFont 'Monaco', Monaco, $monaco;
