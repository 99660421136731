.editor {
    @apply relative;

    .ProseMirror:focus {
        @apply outline-none;
    }

    &__content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        font-size: 16px;

        * {
            caret-color: currentColor;
        }

        a {
            @apply underline cursor-pointer;

            span {
                @apply underline;
            }
        }

        ul {
            @apply block list-disc mx-6;
        }

        ol {
            @apply block list-decimal mx-6;
        }

        h1 {
            @apply text-2xl;
        }

        h2 {
            @apply text-xl;
        }

        h3 {
            @apply text-lg;
        }
    }

    .vc-sketch .vc-sketch-field .vc-editable-input input {
        @apply text-black;
    }
}
