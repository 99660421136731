.dz-preview.dz-success:not(.dz-preview.dz-error) .dz-success-mark, .dz-preview.dz-error .dz-error-mark {
    @apply inline;
  }

.dz-success-mark {
    @apply text-green-dark
}

.dz-error-mark {
    @apply text-red-dark
}

.dz-success-mark, .dz-error-mark {
    @apply hidden;
}

.dz-thumbnail {
    @apply hidden;
}

.dz-image-preview .dz-thumbnail {
    @apply block;
}

.dz-progress {
    @apply bg-green w-0;
}

.dz-preview.dz-error .dz-progress {
    @apply bg-red w-full;
}

.dz-preview.dz-error .dz-error-message {
    @apply block;
}

.dz-error-message {
    @apply hidden;
}

.dz-preview.dz-processing .dz-progress {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.dz-preview.dz-complete .dz-progress {
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}
.dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}
