/**
* seatable-clone and seatable-ghost are applied to the divs that move around the screen
* when dragging and dropping. The clone-hide class is used to remove elements such as the dropdown button
* while the draggable is moving.
*/
.seatable-clone .clone-hide, .seatable-ghost .clone-hide {
    @apply hidden;
}

.seatable-ghost {
    @apply border border-dashed border-purple rounded-md p-2 !important;
}

/**
* Hides the sibling element after the draggable.
* This is used in combination with the -1 sortable placement within
* MovesSeatables.js mixin to force the draggable to always be first.
*/
.seatable-ghost + div {
    @apply hidden;
}
