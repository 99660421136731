.floating-menu {
    @apply absolute flex items-center -mt-1 ml-1 invisible opacity-0 transition ease-in duration-200 border-none right-0;

    z-index: 1;

    &.is-active {
        @apply visible opacity-100;
    }

    .menu-button {
        @apply text-sm p-1 mx-1 text-gray-500 rounded-md;

        &:hover {
            @apply text-gray-600 bg-gray-100;
        }
    }
}
