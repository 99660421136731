@import 'tailwindcss/base';

/**
 * Layouts
 */
@import 'layouts/global';
@import 'layouts/event-wall';
@import 'layouts/invite-list';
@import 'layouts/login';
@import 'layouts/mobile-navigation';
@import 'layouts/navigation';
@import 'layouts/rsvp-form';
@import 'layouts/wall-designer';
@import 'layouts/upgrade-page';


/**
 * Components
 */
@import 'tailwindcss/components';
@import 'components/accordion';
@import 'components/action-menu';
@import 'components/alert';
@import 'components/app-drawer';
@import 'components/badge';
@import 'components/block-select';
@import 'components/button';
@import 'components/checkbox';
@import 'components/radio';
@import 'components/call-to-action';
@import 'components/editor';
@import 'components/editor/main';
@import 'components/file-uploads';
@import 'components/form';
@import 'components/feature-table';
@import 'components/icon';
@import 'components/modal';
@import 'components/number-picker';
@import 'components/panel';
@import 'components/payment-screen';
@import 'components/seating';
@import 'components/slidable-card';
@import 'components/switch';
@import 'components/email-editor';

/**
 * Utilities
 */
@import 'tailwindcss/utilities';
@import 'utilities';

/**
 * Logo animation transforms
 */
#chrt_r, #r_wrp { transform-origin:3325px 1547px; }
#chrt_s { transform-origin:4050px 1547px; }
#chrt_v { transform-origin:4893px 1547px; }
#chrt_p { transform-origin:5826px 1547px; }
#chrt_i { transform-origin:6493px 1547px; }
#chrt_f { transform-origin:7039px 1547px; }
#chrt_y { transform-origin:7828px 1547px; }
