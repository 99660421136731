.mobile-navigation {
    @apply text-sm block pt-2 bg-black relative sm:hidden;

    margin-top: -1px;

    > div {
        @apply flex flex-nowrap overflow-x-auto;

        > a {
            @apply flex-none text-center no-underline pb-2 px-4;
        }
    }

    &::before, &::after{
        @apply absolute top-0 w-8 z-10 pointer-events-none;

        bottom: 4px; /* We're not fading out the navigation purple/active bottom border */
        content: ' ';
    }

    &::before {
        @apply left-0;

        background: rgba(63,63,63,1);
        background: -moz-linear-gradient(left, rgba(63,63,63,1) 0%, rgba(63,63,63,0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(63,63,63,1)), color-stop(100%, rgba(63,63,63,0)));
        background: -webkit-linear-gradient(left, rgba(63,63,63,1) 0%, rgba(63,63,63,0) 100%);
        background: -o-linear-gradient(left, rgba(63,63,63,1) 0%, rgba(63,63,63,0) 100%);
        background: -ms-linear-gradient(left, rgba(63,63,63,1) 0%, rgba(63,63,63,0) 100%);
        background: linear-gradient(to right, rgba(63,63,63,1) 0%, rgba(63,63,63,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f3f3f', endColorstr='#3f3f3f', GradientType=1 );
    }

    &::after {
        @apply right-0;

        background: rgba(63,63,63,0);
        background: -moz-linear-gradient(left, rgba(63,63,63,0) 0%, rgba(63,63,63,1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(63,63,63,0)), color-stop(100%, rgba(63,63,63,1)));
        background: -webkit-linear-gradient(left, rgba(63,63,63,0) 0%, rgba(63,63,63,1) 100%);
        background: -o-linear-gradient(left, rgba(63,63,63,0) 0%, rgba(63,63,63,1) 100%);
        background: -ms-linear-gradient(left, rgba(63,63,63,0) 0%, rgba(63,63,63,1) 100%);
        background: linear-gradient(to right, rgba(63,63,63,0) 0%, rgba(63,63,63,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f3f3f', endColorstr='#3f3f3f', GradientType=1 );
    }
}

.tabs-list.mobile-navigation {
    @apply bg-gray-100;

    &::before {
        background: rgba(247, 247, 247, 1);
        background: -moz-linear-gradient(left, rgba(247, 247, 247, 1) 0%, rgba(247, 247, 247, 0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(247, 247, 247, 1)), color-stop(100%, rgba(247, 247, 247, 0)));
        background: -webkit-linear-gradient(left, rgba(247, 247, 247, 1) 0%, rgba(247, 247, 247, 0) 100%);
        background: -o-linear-gradient(left, rgba(247, 247, 247, 1) 0%, rgba(247, 247, 247, 0) 100%);
        background: -ms-linear-gradient(left, rgba(247, 247, 247, 1) 0%, rgba(247, 247, 247, 0) 100%);
        background: linear-gradient(to right, rgba(247, 247, 247, 1) 0%, rgba(247, 247, 247, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#f7f7f7', GradientType=1 );
    }

    &::after {
        background: rgba(247, 247, 247, 0);
        background: -moz-linear-gradient(left, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(247, 247, 247, 0)), color-stop(100%, rgba(247, 247, 247, 1)));
        background: -webkit-linear-gradient(left, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);
        background: -o-linear-gradient(left, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);
        background: -ms-linear-gradient(left, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);
        background: linear-gradient(to right, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#f7f7f7', GradientType=1 );
    }
}
