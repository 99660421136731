.call-to-action {
    @apply flex p-4 rounded-md bg-white shadow-md text-gray-700 mb-8;

    &.call-to-action-primary {
         @apply border-t-4 border-purple;

        .call-to-action-button {
            @apply bg-purple-50 text-purple hover:bg-purple hover:text-white;
        }
    }

    &.call-to-action-warning {
        @apply border-t-4 border-yellow;

        .call-to-action-button {
            @apply bg-yellow-light text-yellow-dark hover:bg-yellow hover:text-white;
        }
    }
}
