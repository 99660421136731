.switch {
    @apply inline-block relative shrink-0 rounded-full cursor-pointer h-6 w-11;

    &.is-small {
        @apply h-5 w-9;
    }

    &:focus {
        @apply outline-none;
    }
}

.switch:before {
    @apply inline-block h-full w-full rounded-full bg-transparent border border-gray-400 duration-200 transition-colors ease-in-out;

    content: "";
}

.switch[aria-checked="true"]:before {
    @apply bg-purple border-transparent;

    -webkit-box-shadow: inset 0 0 2px theme('colors.purple.DEFAULT');
    box-shadow: inset 0 0 2px theme('colors.purple.DEFAULT');
}

.switch:after {
    @apply absolute inset-x-0 mt-1 rounded-full h-4 w-4 bg-gray-400 duration-200 transition-transform ease-in-out;

    content: "";
    -webkit-transform: translateX(0.25rem);
    transform: translateX(0.25rem);
}
.switch.is-small:after {
    height: 0.875rem;
    margin-top: 0.2rem;
    width: 0.875rem;
}
.switch[aria-checked="true"]:after {
    @apply bg-white;

    -webkit-transform: translateX(1.5rem);
    transform: translateX(1.5rem);
}
.switch.is-small[aria-checked="true"]:after {
    -webkit-transform: translateX(1.125rem);
    transform: translateX(1.125rem);
}
