.logo-slider {
    @apply w-full;

    .slick-slide {
        margin-left: 25px;

        & > div {
            @apply text-center;

            & > img {
                width: auto !important;
            }
        }
    }

    .slick-list {
        margin-left: -25px;
    }
}
