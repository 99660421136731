.shadow-outline-gray {
    box-shadow: -2px -2px 6px -1px rgba(0, 0, 0, 0.1), -2px -2px 4px -1px rgba(0, 0, 0, 0.06);
}

.rsvp-form, .passcode-check {
    &.preview {
        @apply bg-no-repeat bg-center bg-cover bg-fixed;

        background-image: var(--image);
    }

    &:not(.preview) {
        .form-background {
            @apply overflow-hidden;

            &::before {
                @apply fixed bg-no-repeat bg-center bg-cover left-0 top-0 w-full;

                height: var(--fvh, 100vh);
                background-image: var(--image);
                content: '';
                will-change: transform;
                z-index: -1;
            }

            &:not(.wall-inactive)::before {
                @screen md {
                    width: 41.68%;
                    left: 58.33%;
                }
            }
        }
    }
}
