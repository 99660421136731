.modal-content {
    @apply flex flex-col relative align-top h-auto w-full overflow-hidden z-50 origin-top;

    &.full-screen, &.full-screen-on-mobile {
        @apply absolute inset-y-0 max-w-full min-w-full bg-white;
    }

    /* Full screen only on mobile should add 'reset' classes to >= sm screens */
    &.full-screen-on-mobile:not(.full-screen) {
         @apply sm:inset-auto sm:rounded-md sm:max-w-2xl sm:min-w-2xl sm:shadow-2xl;
     }

    /* Maximum height for non-fullscreen modals with long content on >= sm screens */
    &:not(.full-screen) {
        @screen sm {
            max-height: 75%;

            &.full-height {
                max-height: 95%;
            }
        }
    }
}

.modal-footer {
    .button {
        @apply w-full sm:w-auto;
    }

    .button + .button {
        @apply mt-4 sm:m-0;
    }

    .button + .button:not(:last-child) {
        @apply sm:mr-4;
    }
}
