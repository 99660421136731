@import 'tippy.js/themes/light';

table.feature-table {
    @apply w-full max-w-6xl mx-auto border-collapse border-t;

    thead tr th:not(:first-child) {
        @apply text-xl text-purple tracking-wide text-center py-3;
    }

    th:not(:first-child):not(:last-child), td:not(:first-child):not(:last-child) {
        @apply border-r;
    }

    tbody tr.category:not(:first-child) td {
        @apply pt-4 border-t;
    }

    tbody tr.feature:has(+tr.category) td {
        @apply pb-4;
    }

    tbody tr.category {
        td:first-child {
            @apply text-2xl;
        }

        td:not(:first-child) {
            @apply text-center;
        }
    }

    tbody tr.feature {
        td:first-child {
            @apply text-sm;
        }

        td:not(:first-child) {
            @apply text-sm text-center py-3;
        }
    }
}
