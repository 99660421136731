@import 'utilities/breaks';
@import 'utilities/ios';
@import 'utilities/transitions';
@import 'utilities/typography';
@import 'utilities/css-icons';

.appearance-no-spinners, .appearance-no-spinners::-webkit-outer-spin-button, .appearance-no-spinners::-webkit-inner-spin-button{
    @apply appearance-none;
    -webkit-appearance: none;
    -moz-appearance:textfield;
}

.box-sizing-border {
    box-sizing: border-box;
}

.box-sizing-content {
    box-sizing: border-box;
}

.cursor-grab {
    cursor: grab;
}

.touch-action-none {
    touch-action: none;
}

.blur {
    filter: blur(2px);
    opacity: 0.5;
}

main.blur, footer.blur {
    @apply pointer-events-none;
}

[v-cloak] {
    @apply hidden;
}

.h-window-full {
    height: var(--fvh, 100vh);
}

.scrollbar-none {
    scrollbar-width: none;
}

body .only-cordova {
    display: none;
}

body.cordova .only-cordova {
    display: block !important;
}

body .only-not-cordova {
    visibility: visible;
}

body.cordova .only-not-cordova {
    visibility: hidden !important;
}
