.alert {
    @apply flex mb-4 p-4 rounded-md font-medium bg-gray-100 text-gray-600;

    &.alert-sm {
        @apply p-2 text-sm;
    }

    .alert-button {
        @apply flex p-2 text-white rounded-md no-underline w-full justify-center shrink-0 cursor-pointer;
    }

    &.alert-success {
        @apply bg-green-light text-green-dark;

        .alert-button {
            @apply bg-green text-green-light hover:bg-green-dark;
        }
    }

    &.alert-warning {
        @apply bg-yellow-light text-yellow-dark;

        .alert-button {
            @apply bg-yellow text-yellow-dark hover:bg-yellow-dark hover:text-yellow;
        }

        .alert-close-button {
            @apply text-yellow-dark hover:text-yellow;
        }
    }

    &.alert-error {
        @apply bg-red-light text-red-dark;

        .alert-button {
            @apply bg-red text-red-light hover:bg-red-dark;
        }

        .alert-close-button {
            @apply text-red-dark hover:text-red;
        }
    }

    &.alert-primary {
        @apply bg-purple-50 text-purple;

        .alert-button {
            @apply bg-purple hover:bg-purple;
        }
    }

    &.alert-info {
        @apply bg-teal-light text-teal-dark;

        .alert-button {
            @apply bg-teal text-teal-light hover:bg-teal-dark;
        }

        .alert-close-button {
            @apply text-teal-dark hover:text-teal;
        }
    }

    &.alert-global {
        @apply flex flex-col shrink-0 items-center mb-0 rounded-none md:flex-row;

        > p {
            @apply flex-auto text-center md:text-left;
        }

        .alert-button {
            @apply mt-2 md:ml-4 md:w-auto md:mt-0;
        }
    }
}
