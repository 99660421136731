.form-field-wrapper {
    @apply block relative w-full;

    + .form-field-wrapper {
        @apply mt-8;
    }
}

textarea.form-field {
    @apply appearance-none rounded-lg border-2 border-gray-200;
}

.form-field {
    @apply block w-full appearance-none outline-none py-2 px-4 border border-gray-300 focus:border-purple-400 rounded-lg bg-white text-gray-800 placeholder-gray-400 leading-normal transition duration-150 ease-in-out;

    &::placeholder {
        @apply text-gray-400;
    }

    &.form-field-success {
        @apply border-green focus:border-green;
    }

    &.form-field-error {
        @apply border-red focus:border-red;
    }

    &:focus {
        @apply border-purple-lighter outline-none;
    }

    &:disabled {
        @apply text-opacity-50;
   }

    &.no-spinner {
         /* Firefox */
         -moz-appearance: textfield;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.field {
    @apply block w-full bg-white appearance-none py-3 px-4 border border-gray-200 rounded-md text-gray-800 leading-normal duration-200 ease-in-out transition-all;

    &:hover {
        @apply border-purple-200;
    }

    &::placeholder {
         @apply text-gray-400;
    }

    &:focus {
         @apply border-purple-lighter outline-none;
    }
}

.form-panel {
    @apply block w-full p-4 rounded-md border border-gray-200 bg-gray-50;
}
