.slidable-cards {
    .tns-carousel {
        @apply md:row md:mx-0 md:w-full;
    }

    .tns-nav-active {
        @apply border border-purple;

        background-color: theme('colors.purple.500') !important;
    }
}
