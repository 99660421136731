.action-menu {
    @apply flex items-center justify-between bg-white fixed z-40 bottom-0 inset-x-0 overflow-hidden md:static md:inset-auto md:hidden;

    box-shadow: 0 -4px 6px rgba(50, 50, 93, .11), 0 -1px 3px rgba(0, 0, 0, .08);
    padding-bottom: calc(constant(safe-area-inset-bottom) * 0.5) !important;
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5) !important;

    .action-menu-item {
        @apply flex flex-1 flex-col justify-center items-center text-center h-full appearance-none;

        &:focus {
            @apply text-purple outline-none;
        }

        &:not(:last-child) {
            @apply border-r;
        }
    }
}
