.app-drawer {
    @apply fixed h-full w-full top-0 bottom-0 overflow-x-hidden overflow-y-auto bg-white transition-slow transition-transform ease-in-out;

    z-index: 1000;
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;

    &.left {
        left: 0px;
        transform: translateX(-100%);
    }

    &.right {
        right: 0px;
        transform: translateX(100%);
    }

    &.open {
        @apply shadow-lg;

        transform: translateX(0%);
    }

    @screen sm {
        width: 370px;
    }

    .app-drawer-main {
        @apply flex flex-1 p-4 overflow-y-auto;
    }

    .app-drawer-footer {
        @apply flex items-center bg-gray-100 p-4;

        padding-bottom: constant(safe-area-inset-bottom);
    }
}
