.event-wall {
    .block-list-move {
        transition: transform 0.75s;
    }

    .cover-block {
        h1, h2, .title, .subtitle {
            @apply p-1;

            text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
        }

        .title {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        .subtitle {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        /** Hack for backwarrds-compatibility with Quill since it's removal */
        .editor.title .editor__content, .editor.subtitle .editor__content {
            font-size: inherit !important;
        }
    }

    .image-block {
        @apply pb-0 !important;
    }

    .map-block {
        @apply pb-0 !important;
    }

    .schedule-block {
        .event-list-move {
            transition: transform 0.75s;
        }

        .event {
            &:last-of-type {
                @apply border-l-0;
            }

            .icon {
                @apply rounded-full h-10 w-10 flex items-center justify-center border-2 absolute left-0;

                transform: translateX(calc(-50% - 1px)) translateY(-25%);
            }
        }
    }

    .text-block {
        .content p {
            @apply leading-normal px-6;

            &:not(:last-child) {
                @apply mb-5;
            }

            a {
                @apply underline;
            }
        }
    }
}

.event-menu {
    @apply z-50 bg-white absolute top-0 left-0 h-full;

    width: 240px;
    transform: translateX(-100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    will-change: transform;

    &.menu-open {
        transform: none;
    }
}


#menu-button {
    z-index: 51;
}

.wall-preview {
    @apply relative;

    &.mobile {
        height: 865px;
    }

    .event-menu {
        opacity: 0;

        &.menu-open {
            opacity: 1;
        }
    }
}
