.badge {
    @apply inline-flex py-1 px-3 bg-gray-100 rounded-md uppercase tracking-wide text-xs font-semibold text-gray-600 no-underline;

    &.badge-error {
        @apply bg-red-light text-red-dark;
    }

    &.badge-info {
        @apply bg-teal-light text-teal-dark;
    }

    &.badge-primary {
        @apply bg-purple-50 text-purple;
    }

    &.badge-success {
        @apply bg-green-light text-green-dark;
    }

    &.badge-warning {
        @apply bg-yellow-light text-yellow-dark;
    }
}
