.checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    @apply bg-gray-50 border rounded-md;
}

/* On mouse-over, add a greyer border */
.checkbox-container:hover input ~ .checkmark {
    @apply border-gray-400;
}

/* When the checkbox is checked, add a purple background */
.checkbox-container input:checked ~ .checkmark {
    @apply bg-purple;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
