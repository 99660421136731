@screen md {
    #logo {
        visibility: hidden;
    }

    #login-form {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }

    #copyright, #signupLink {
        overflow: hidden;
        max-height: 0;
    }

    .logoEntrance {
        animation-name: logoEntrance;
        -webkit-animation-name: logoEntrance;

        animation-duration: 1.6s;
        -webkit-animation-duration: 1.6s;

        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;

        visibility: visible !important;
    }

    @keyframes logoEntrance {
        0% {
            transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
            opacity: 0.2;
        }
        30% {
            transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
            opacity: 1;
        }
        45% {
            transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
            opacity: 1;
        }
        60% {
            transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
            opacity: 1;
        }
        75% {
            transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
            opacity: 1;
        }
        90% {
            transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
            opacity: 1;
        }
        100% {
            transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
            opacity: 1;
        }
    }

    .loginFormPullDown {
        animation-name: loginFormPullDown;
        -webkit-animation-name: loginFormPullDown;

        animation-delay: 2.2s;
        -webkit-animation-delay: 2.2s;

        animation-duration: 1.1s;
        -webkit-animation-duration: 1.1s;

        animation-timing-function: ease-out;
        -webkit-animation-timing-function: ease-out;

        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;

        transform-origin: 50% 0%;
        -ms-transform-origin: 50% 0%;
        -webkit-transform-origin: 50% 0%;
    }

    @keyframes loginFormPullDown {
        0% {
            transform: scaleY(0.1);
            max-height: 0;
            opacity: 0;
        }
        40% {
            transform: scaleY(1.02);
            max-height: 455px;
            opacity: 1;
        }
        60% {
            transform: scaleY(0.98);
        }
        80% {
            transform: scaleY(1.01);
        }
        100% {
            transform: scaleY(0.98);
        }
        80% {
            transform: scaleY(1.01);
        }
        100% {
            transform: scaleY(1);
            max-height: 495px;
            opacity: 1;
        }
    }
}
