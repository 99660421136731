.dropdown-inverse {
    @apply bg-black rounded-none border-none mt-0;

    .dropdown-item {
        @apply text-white flex items-center;

        > .dropdown-item-icon {
            @apply mr-2 shrink-0;
        }

        &:hover {
            @apply bg-transparent text-purple-lighter;
        }
    }
}

#in-event-sidebar-nav {
    transition: max-width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

#in-event-header, #main-content, #form-builder-menu {
    transition: margin 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@screen md {
    body:not(.nav-collapsed) {
        #in-event-sidebar-nav {
            max-width: 18rem;
        }
    }

    body.nav-collapsed {
        #in-event-sidebar-nav {
            max-width: 2.7rem;
        }

        #in-event-header, #main-content, #form-builder-menu {
            margin-left: 2.7rem !important;
        }
    }
}

#accordion-event-nav {
    .nav-item, .nav-sub-item {
        @apply whitespace-nowrap;
    }

    .nav-item:not(.active) {
        @apply text-gray-300 border-l-transparent hover:bg-violet-600 hover:text-white hover:border-l-purple-lighter;
    }

    .nav-item.active {
        @apply bg-violet-600 text-white border-l-purple-lighter
    }

    .nav-sub-item:not(.active) {
        @apply text-gray-300 hover:text-purple-200;
    }

    .nav-sub-item.active {
        @apply font-medium text-purple-lighter;
    }
}
