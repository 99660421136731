.bubble-menu {
    @apply absolute flex bg-black rounded-xl px-4 py-1 mb-1 items-center justify-center invisible opacity-0;

    z-index: 1001;
    transform: translateX(-35%);
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        @apply opacity-100 visible;
    }

    .menu-button {
        @apply relative inline-block h-full text-snow-700 mr-2 cursor-pointer px-1 pb-1;

        &:last-child {
            @apply mr-0;
        }

        &:hover {
            @apply text-white;
        }

        &.is-active {
            @apply text-white;
        }
    }
}
