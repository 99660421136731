.button {
    @apply border border-transparent inline-flex items-center justify-center relative py-3 px-5 rounded-md text-center text-gray-700 no-underline font-semibold text-sm uppercase tracking-wide bg-gray-200 transition duration-150 ease-in-out cursor-pointer;

    &:hover {
        @apply bg-gray-300 text-gray-900;
    }

    > .svg-icon {
        @apply h-6 w-6 mr-2 leading-0;
    }

    &.button-primary {
         @apply bg-purple text-purple-50 hover:bg-purple-light hover:text-white;
     }

    &.button-outline {
         @apply bg-gray-100 border-purple text-purple hover:bg-purple hover:text-white;
     }

    &.button-empty {
         @apply normal-case bg-white text-gray-800 border border-gray-200 hover:border-purple hover:text-purple;
     }

    &.button-primary-soft {
        @apply bg-purple-50 text-purple hover:bg-purple-200 hover:text-purple-800;
    }

    &.button-secondary {
        @apply border-purple border-2 bg-white text-purple hover:bg-purple-500 hover:text-white hover:border-purple-light;
    }

    &.button-info {
        @apply bg-teal-light text-teal;

        &:hover {
            @apply bg-teal text-white;
        }

        &.button-inverse {
            @apply bg-white text-teal border-2 border-teal;

            &:hover {
                @apply bg-teal text-white;
            }
        }
    }

    &.button-warning-soft {
        @apply bg-yellow-light text-yellow-dark hover:bg-yellow hover:text-yellow-dark;
    }

    &.button-warning-dark {
        @apply bg-yellow text-white hover:bg-yellow-dark hover:text-white;
    }

    &.button-danger {
        @apply bg-red text-white hover:bg-red hover:bg-opacity-75 hover:text-white;
    }

    &.button-danger-soft {
        @apply bg-red-light text-red hover:bg-red-light hover:bg-opacity-75 hover:text-red;
    }

    &.button-soft {
        @apply bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-gray-700;
    }

    &.button-dark {
        @apply bg-gray-700 text-white hover:bg-gray-800 hover:text-white;
    }

    &.button-blue {
        @apply bg-blue text-white;

        &:hover {
            @apply bg-blue-dark;
        }
    }

    &.button-sm {
        @apply py-2 px-3 text-xs;

        > .svg-icon {
            @apply h-4 w-4;
        }
    }

    &.button-md {
        @apply py-2 px-4 text-sm;

        > .svg-icon {
            @apply h-5 w-5;
        }
    }

    &.button-lg {
        @apply py-4 px-6 text-lg;

        > .svg-icon {
            @apply h-7 w-7 mr-3;
        }
    }
}

.button-text {
    @apply inline-flex items-center font-medium transition duration-150 ease-in-out cursor-pointer text-gray-700;

    &:hover {
        @apply text-gray-800;
    }

    &.button-primary {
        @apply text-purple;

        &:hover {
            @apply text-purple-light;
        }
    }

    &.button-info {
        @apply text-teal;

        &:hover {
            @apply text-teal-dark;
        }
    }
}

.button-icon {
    @apply inline-flex items-center transition duration-150 ease-in-out cursor-pointer text-gray-600 hover:text-gray-800;

    > .svg-icon {
        @apply h-6 w-6 leading-0;
    }

    &.button-primary {
        @apply text-purple hover:text-purple-light;
    }

    &.button-soft {
        @apply text-gray-400 hover:text-gray-600;
    }

    &.button-blue {
        @apply text-white hover:text-white;
    }

    &.button-blue-dark {
        @apply text-white hover:text-white;
    }

    &.button-info {
        @apply text-teal hover:text-teal-dark;
    }

    &.button-bg {
        @apply p-1 rounded-md bg-gray-300;

        &:hover {
            @apply bg-gray-400 text-gray-800;
        }

        &.button-soft {
            @apply bg-gray-100 text-gray-500;

            &:hover {
                @apply bg-gray-200 text-gray-600;
            }
        }

        &.button-primary {
            @apply bg-purple text-white;

            &:hover {
                @apply bg-purple-light text-white;
            }
        }

        &.button-primary-soft {
            @apply bg-purple-100 text-purple;

            &:hover {
                @apply bg-purple-200 text-purple-800;
            }
        }

        &.button-blue {
            @apply bg-blue;

            &:hover {
                @apply bg-blue-dark text-white;
            }
        }

        &.button-blue-dark {
            @apply bg-blue-dark;

            &:hover {
                @apply text-white;
            }
       }
    }

    &.button-sm {
        > .svg-icon {
            @apply h-4 w-4 leading-0;
        }
    }

    &.button-md {
        > .svg-icon {
            @apply h-5 w-5 leading-0;
        }
    }

    &.button-lg {
        > .svg-icon {
            @apply h-8 w-8 leading-0;
        }
    }

    &.button-round {
        @apply rounded-full;
    }
}

button:focus {
    @apply outline-none;
}
