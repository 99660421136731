body {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

header {
    margin-top: calc(constant(safe-area-inset-top) * -1.4);
    margin-top: calc(env(safe-area-inset-top) * -1.4);

    .navbar {
        padding-top: constant(safe-area-inset-top) !important;
        padding-top: env(safe-area-inset-top) !important;
    }
}
