.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active { transition: all .4s cubic-bezier(0.390, 0.575, 0.565, 1.000); }
.slide-left-enter { transform: translateX(100%); }
.slide-left-leave-active { transform: translateX(-100%); }
.slide-right-enter { transform: translateX(-100%); }
.slide-right-leave-active { transform: translateX(100%); }

.fade-enter-active, .fade-leave-active { transition: opacity .4s; }
.fade-enter, .fade-leave-to { opacity: 0; }

fade-slide-enter-active, .fade-slide-leave-active {
    transition: opacity .25s, transform .25s;
}

.fade-slide-leave-to {
    opacity: 0;
    transform: translate(40px);
}

.fade-slide-enter {
    opacity: 0;
    transform: translate(-40px);
}

.slide-vertical-enter-active, .slide-vertical-leave-active { transition: .4s; }
.slide-vertical-enter-to, .slide-vertical-leave { max-height: 200px; overflow: hidden; }
.slide-vertical-enter, .slide-vertical-leave-to { overflow: hidden; max-height: 0; }

.crossfade-enter-active, .crossfade-leave-active { transition: opacity 1s; }
.crossfade-enter, .crossfade-leave-to { opacity: 0; }

.slide-fade-enter-active { transition: all .3s ease; }
.slide-fade-leave-active { transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0); }
.slide-fade-enter, .slide-fade-leave-to { transform: translateX(10px); opacity: 0; }

.slide-fade-vertical-enter-active { transition: all .5s ease; }
.slide-fade-vertical-leave-active { transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0); }
.slide-fade-vertical-enter, .slide-fade-vertical-leave-to { transform: translateY(-20px); opacity: 0; }

.transition-fastest {
    transition-duration: 75ms;
}
.transition-faster {
    transition-duration: .1s;
}
.transition-fast {
    transition-duration: .15s;
}
.transition-medium {
    transition-duration: .2s;
}
.transition-slow {
    transition-duration: .3s;
}
.transition-slower {
    transition-duration: .5s;
}
.transition-slowest {
    transition-duration: .7s;
}

